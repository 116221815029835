<template>
  <v-list class="group-list" :loading="true">
    <v-list-item-group v-model="selectItem" color="primary" mandatory>
      <div class="d-flex">
        <m-icon icon="medexpert-group-list" class="mt-1 ml-4" />
        <m-select
          v-model="selectGroup"
          :items="items"
          :clearable="false"
          item-value="value"
          item-text="text"
          class="mx-2 pt-5 group-list__input-group"
          label="Выберите группировку"
        ></m-select>
      </div>

      <v-list-item :value="null">
        <v-list-item-content>
          <v-list-item-title>Все</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action v-if="values.length">
          {{ values.reduce((acc, value) => (acc += value.Count), 0) }}
        </v-list-item-action>
      </v-list-item>
      <div class="group-list__wrapper">
        <v-list-item v-for="item in values" :key="item.Id" :value="item.Id">
          <v-list-item-content>
            <v-list-item-title :title="item.Name">{{
              item.Name
            }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            {{ item.Count }}
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-list-item-group>
  </v-list>
</template>
<script>
import { debounce } from "lodash";

export default {
  components: {
    mSelect: () => import("@/components/base/inputs/mSelect.vue"),
  },
  inject: [
    "apiService",
    "resetPagination",
    "provideHeaders",
    "_provideApiServiceParams",
  ],
  props: {
    groupBy: { default: null },
    options: { type: Object, default: null },
  },
  data() {
    return {
      selectItem: null,
      selectGroup: this.groupBy.length ? this.groupBy[0] : null,
      values: [],
      listGroupBy: [this.selectGroup],
    };
  },
  computed: {
    items() {
      if (this?.provideHeaders?.value) {
        return this.provideHeaders.value
          .filter((e) => !e.notGroup)
          .map((e) => ({ ...e, disabled: null }));
      } else return [];
    },
  },
  watch: {
    options() {
      this.init();
    },
    selectItem() {
      this.$emit("update:listGroupByValue", [this.selectItem]);
      this.resetPagination();
    },
    selectGroup: {
      immediate: true,
      handler() {
        this.selectItem = [null];
        this.$emit("update:listGroupBy", [this.selectGroup]);
        this.init();
      },
    },
  },
  async mounted() {
    this.init();

    this.$watch(
      () => this._provideApiServiceParams.value,
      () => {
        this.init();
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    init: debounce(async function () {
      const res = (
        await this.apiService.getListByGroup(
          {
            filters: this.options?.filters,
            groupBy: [this.selectGroup],
            groupDesc: [false],
          },
          this._provideApiServiceParams.value
        )
      ).data;
      if (res?.length) {
        this.values = res[0].Values;
      }
    }, 500),
  },
};
</script>
<style lang="scss">
.group-list {
  width: 275px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 233px) !important;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 2px 8px 30px var(--v-overlay-base);
  border-radius: 10px !important;
  border: solid 1px var(--v-blue-grey-lighten3) !important;
  resize: horizontal;
  overflow: hidden;

  &__wrapper {
    overflow: auto;
  }

  .v-select__selection {
    color: var(--v-primary-base);
  }

  svg.medexpert-group-list {
    width: 28px !important;
    height: 28px !important;
  }
  .v-item-group {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .v-list-item {
    .v-list-item__title,
    .v-list-item__action {
      color: var(--v-text-lighten1);
    }
    &--active {
      .v-list-item__title,
      .v-list-item__action {
        color: var(--v-primary-base) !important;
        font-weight: 700;
      }
    }
  }
  .v-select__selection {
    font-size: 14px;
  }

  .theme--light.v-subheader {
    color: var(--v-text-lighten1);
    font-weight: 500;
  }

  .v-list-item__content {
    color: var(--v-blue-grey-base);
  }

  .v-icon {
    margin-top: 6px;
    margin-right: 8px;
  }
}
</style>
